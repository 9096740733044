import { useRoutes } from 'react-router-dom';

// routes
import AuthRoutes from './AuthenticationRoutes';
import ErrorRoute from './ErrorRoute';
import MainRoutes from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

const ThemeRoutes = (isLoggedIn) => [
    AuthRoutes(isLoggedIn), MainRoutes(isLoggedIn), ErrorRoute(isLoggedIn)
];


export default ThemeRoutes;