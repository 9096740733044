import { API_SUCCESS, API_ERROR, AUTHENTICATION_SUCCESS } from '../actions/api';
import { LOGOUT } from '../actions/auth';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const userReducer = (
    state = {
        sid: cookies.get('sid'),
        isAuth: !!localStorage.getItem('qun'),
        user: localStorage.getItem('qun') || null,
        isLoading: false,
        error: null,
        authToken: localStorage.getItem('token') || null,
        role: localStorage.getItem('qrole') || null
    },
    action
) => {
    switch (action.type) {
        case AUTHENTICATION_SUCCESS: 
            return { ...state, authToken: action.payload.csrfToken }
        case API_SUCCESS: 
            console.log(action)
            localStorage.setItem('token', action.payload.token)
            cookies.set('sid', action.payload.id, {expires: new Date(Date.now() + 7 * 86400000), path: '/'});
            if (action.payload.username){
                localStorage.setItem('qun', action.payload.username)
            } else localStorage.setItem('qun', action.payload.first_name)
            localStorage.setItem('qrole', action.payload.role)

            return { ...state, isAuth: true, user: action.payload};
        case API_ERROR:
            return { ...state, error: action.payload };
        // case SET_LOADER:
        //     return { ...state, isLoading: action.payload };
        case LOGOUT: 
            cookies.remove('sid');
            localStorage.removeItem('qun');
            localStorage.removeItem('token')
            return { ...state, isAuth: false, user: {} };
        default:
            return state;
    }
}

export default userReducer;