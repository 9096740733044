// assets
import { HiChartSquareBar } from "react-icons/hi";
import { IoAnalyticsOutline } from "react-icons/io5";

// constant
const icons = { HiChartSquareBar, IoAnalyticsOutline };

const GeneralRoutes = {
  id: "overview",
  title: "Overview",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/overview/dashboard",
      icon: icons.HiChartSquareBar,
      breadcrumbs: false,
    },
    // {
    //   id: "analytics",
    //   title: "Analytics",
    //   type: "item",
    //   url: "/overview/analytics",
    //   icon: icons.IoAnalyticsOutline,
    //   breadcrumbs: false,
    // },
  ],
};

export default GeneralRoutes;
