import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../store/reducers';
import { apiMiddleware } from "../store/middlewares/apiMiddleware";
import { authMiddleware } from "../store/middlewares/authMiddleware"

const initialState = {};

// add middleware after
const middlewares = [thunk];

export const store = createStore(
    rootReducer,
    initialState,
    compose(
        applyMiddleware(
            ...middlewares, 
            authMiddleware,
            apiMiddleware, 
        )
    )
)