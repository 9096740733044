import { useState, useRef, useEffect, useContext } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// mui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Popper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

import { Auth } from "aws-amplify";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "components/Cards/MainCard";
import Transitions from "components/Extended/Transitions";
import UpgradePlanCard from "./UpgradePlanCard";
import User1 from "assets/images/users/user-round.svg";
import { ThemeContext } from "app/ThemeContext";

// assets
// import { IconLogout, IconSearch, IconSettings, IconUser } from '@tabler/icons';
import { BiLogOut, BiSearchAlt } from "react-icons/bi";
import { AiFillSetting } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { getCurrTime } from "helpers/utils";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const context = useContext(ThemeContext);
  const darkMode = context.state.darkMode;
  const customization = useSelector((state) => state.customization);
//   const { user, role } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const [sdm, setSdm] = useState(true);
  const [value, setValue] = useState("");
  const [notification, setNotification] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [currTime, setCurrTime] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [user, setUser] = useState(null);

  const getUserInfo = async () => {
    Auth.currentSession()
      .then((data) => {
        const { payload } = data.idToken;
        const { email, given_name } = payload;
        const userGroup = payload['cognito:groups'];

        let currUser = {
          given_name,
          email,
          role: userGroup[0]
        };

        setUser(currUser);
        setFetched(true);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const curr = getCurrTime();
    setCurrTime(curr);
    getUserInfo();
  }, []);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    console.log("Logging out...");
    try {
      await Auth.signOut();
    } catch (err) {
      console.log({ err });
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: darkMode
            ? theme.palette.secondary.light
            : theme.palette.primary.light,
          backgroundColor: darkMode
            ? theme.palette.secondary.light
            : theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: darkMode
              ? theme.palette.secondary.dark
              : theme.palette.primary.dark,
            background: darkMode
              ? theme.palette.secondary.dark
              : theme.palette.primary.dark, //`${theme.palette.primary.main}!important`,
            color: darkMode
              ? theme.palette.secondary.light
              : theme.palette.primary.light,
            "& svg": {
              stroke: darkMode
                ? theme.palette.secondary.light
                : theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            src={User1}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
              cursor: "pointer",
              // color: theme.palette.common.white
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="primary"
          />
        }
        label={
          <AiFillSetting
            stroke={1.5}
            size="1.5rem"
            color={
              darkMode
                ? theme.palette.secondary.main
                : theme.palette.primary.main
            }
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                {fetched && (
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Box sx={{ p: 2 }}>
                      <Stack>
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                        >
                          <Typography variant="h4">{currTime},</Typography>
                          <Typography
                            component="span"
                            variant="h4"
                            sx={{ fontWeight: 400 }}
                          >
                            {user.given_name}
                          </Typography>
                        </Stack>
                        <Typography variant="subtitle2">{user.role}</Typography>
                      </Stack>
                      <OutlinedInput
                        sx={{ width: "100%", pr: 1, pl: 2, my: 2 }}
                        id="input-search-profile"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        placeholder="Search profile options"
                        startAdornment={
                          <InputAdornment position="start">
                            <BiSearchAlt
                              stroke={1.5}
                              size="1rem"
                              color={theme.palette.grey[500]}
                            />
                          </InputAdornment>
                        }
                        aria-describedby="search-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      <Divider />
                    </Box>
                    <PerfectScrollbar
                      style={{
                        height: "100%",
                        maxHeight: "calc(100vh - 250px)",
                        overflowX: "hidden",
                      }}
                    >
                      <Box sx={{ p: 2 }}>
                        <UpgradePlanCard />
                        <Divider />
                        <Card
                          sx={{
                            bgcolor: theme.palette.primary.light,
                            my: 2,
                          }}
                        >
                          <CardContent>
                            <Grid container spacing={3} direction="column">
                              <Grid item>
                                <Grid
                                  item
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Grid item>
                                    <Typography variant="subtitle1">
                                      Start DND Mode
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Switch
                                      color="primary"
                                      checked={sdm}
                                      onChange={(e) => setSdm(e.target.checked)}
                                      name="sdm"
                                      size="small"
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Grid
                                  item
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Grid item>
                                    <Typography variant="subtitle1">
                                      Allow Notifications
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Switch
                                      checked={notification}
                                      onChange={(e) =>
                                        setNotification(e.target.checked)
                                      }
                                      name="sdm"
                                      size="small"
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                        <Divider />
                        <List
                          component="nav"
                          sx={{
                            width: "100%",
                            maxWidth: 350,
                            minWidth: 300,
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: "10px",
                            [theme.breakpoints.down("md")]: {
                              minWidth: "100%",
                            },
                            "& .MuiListItemButton-root": {
                              mt: 0.5,
                            },
                          }}
                        >
                          <ListItemButton
                            sx={{
                              borderRadius: `${customization.borderRadius}px`,
                            }}
                            selected={selectedIndex === 0}
                            onClick={(event) =>
                              handleListItemClick(event, 0, "/utils/settings")
                            }
                          >
                            <ListItemIcon>
                              <AiFillSetting stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body2">
                                  Account Settings
                                </Typography>
                              }
                            />
                          </ListItemButton>
                          <ListItemButton
                            sx={{
                              borderRadius: `${customization.borderRadius}px`,
                            }}
                            selected={selectedIndex === 1}
                            onClick={(event) =>
                              handleListItemClick(
                                event,
                                1,
                                "/user/social-profile/posts"
                              )
                            }
                          >
                            <ListItemIcon>
                              <FaUserAlt stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Grid
                                  container
                                  spacing={1}
                                  justifyContent="space-between"
                                >
                                  <Grid item>
                                    <Typography variant="body2">
                                      Social Profile
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Chip
                                      label="02"
                                      size="small"
                                      sx={{
                                        bgcolor: theme.palette.warning.dark,
                                        color: theme.palette.background.default,
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              }
                            />
                          </ListItemButton>
                          <ListItemButton
                            sx={{
                              borderRadius: `${customization.borderRadius}px`,
                            }}
                            selected={selectedIndex === 4}
                            onClick={handleLogout}
                          >
                            <ListItemIcon>
                              <BiLogOut stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body2">Logout</Typography>
                              }
                            />
                          </ListItemButton>
                        </List>
                      </Box>
                    </PerfectScrollbar>
                  </MainCard>
                )}
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
