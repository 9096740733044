export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const AUTHENTICATION = 'AUTHENTICATION';

export const login = (user) => {
    return {
        type: LOGIN,
        payload: user,
    }
}

export const logout = () => {
    return {
        type: LOGOUT
    }
}

export function resetPassword({ email }){
    return dispatch => {
        dispatch({
            payload: email,
            type: RESET_PASSWORD
        });
    }
}

export const authentication = () => {
    return {
        type: AUTHENTICATION
    }
}