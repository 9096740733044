import { lazy } from "react";
import { Navigate } from "react-router-dom"

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";

// dashboard routing
const DashboardPage = Loadable(lazy(() => import('views/dashboard')));
// const Analytics = Loadable(lazy(() => import('views/dashboard/Analytics.jsx')))

// products routing
const ProductsInventoryPage = Loadable(lazy(() => import('views/products/Inventory')));
const ProductsCreationPage = Loadable(lazy(() => import('views/products/Inventory/Creation')))
const ProductsViewingPage = Loadable(lazy(() => import('views/products/Inventory/View')))
const ProductsBundlesPage = Loadable(lazy(() => import('views/products/Bundles')))
const ProductsCollectionsPage = Loadable(lazy(() => import('views/products/Collections')))
const ProductsCollectionsCreationPage = Loadable(lazy(() => import('views/products/Collections/Creation')))

// jobs routing
const JobListingsPage = Loadable(lazy(() => import('views/jobs/Listing')));
const JobCreationPage = Loadable(lazy(() => import('views/jobs/Creation')))
const JobEditingPage = Loadable(lazy(() => import('views/jobs/Editing')))

// User 
const UserClientPage = Loadable(lazy(() => import('views/users')))
const UserLocationPage = Loadable(lazy(() => import('views/locations/Listing')))

// utilities routing
const UtilsSettings = Loadable(lazy(() => import('views/utilities/Settings')));
const NotificationPage = Loadable(lazy(() => import('views/utilities/Notifications')))

const MainRoutes = (isLoggedIn) => {
  return {
  path: "/",
  element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
  children: [
    {
      path: "/dashboard",
      element: <Navigate to="/overview/dashboard" />
    },
    {
      path: "/overview/dashboard/",
      element: <DashboardPage />,
    },
    // {
    //   path: "/overview/analytics",
    //   element: <Analytics />,
    // },
    {
      path: "/products/inventory",
      element: <ProductsInventoryPage />
    },
    {
      path: "/products/creation",
      element: <ProductsCreationPage />
    },
    {
      path: "/products/view/:id",
      element: <ProductsViewingPage />
    },
    {
      path: "/products/bundles",
      element: <ProductsBundlesPage />
    },
    {
      path: "/products/collections",
      element: <ProductsCollectionsPage />
    },
    {
      path: "/products/collections/creation",
      element: <ProductsCollectionsCreationPage />
    },
    {
      path: "/job/listings",
      element: <JobListingsPage />
    },
    {
      path: "/job/creation",
      element: <JobCreationPage />
    },
    {
      path: "/job/editing",
      element: <JobEditingPage />
    },
    {
      path: "/user/clients",
      element: <UserClientPage />
    },
    {
      path: "/user/locations",
      element: <UserLocationPage />
    },
    {
      path: "/utils/settings",
      element: <UtilsSettings />
    },
    {
      path: "/utils/notifications",
      element: <NotificationPage />
    }
  ]}
};

export default MainRoutes;
