import MinimalLayout from "layout/MinimalLayout";
import { Navigate } from "react-router-dom"
import { lazy } from "react";

import Loadable from "components/Loadable";

// error routing
const Error = Loadable(lazy(() => import("views/error")));

const ErrorRoute = (isLoggedIn) => {
  return {
    path: "/",
    element: isLoggedIn ? <MinimalLayout /> : <Navigate to="/login" />,
    children: [
      {
        path: "*",
        element: <Error />,
      },
    ],
  };
};

export default ErrorRoute;
