import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import App from "./app/App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./helpers";
import { Amplify, Auth } from "aws-amplify";
import AmpConfig from "aws-exports"

import "./index.scss";
// import { config } from 'dotenv';
import reportWebVitals from "./reportWebVitals";
import 'assets/scss/style.scss';

Amplify.configure(AmpConfig)
Auth.configure(AmpConfig)

// config({ path: '.env' + process.env.NODE_ENV });

axios.defaults.baseURL = "http://www.api.quasaonline.com" //process.env.REACT_APP_BASE_URL
const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.post["Content-Type"] = "application/json";

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
