import { MdWork, MdAdd, MdModeEditOutline } from "react-icons/md";

const icons = { MdWork, MdAdd, MdModeEditOutline };

const CareerRoutes = {
  id: "career",
  title: "Career",
  type: "group",
  children: [
    {
      id: "listings",
      title: "Listings",
      type: "item",
      url: "/job/listings",
      icon: icons.MdWork,
      breadcrumbs: false,
    },
    // {
    //     id: "editing",
    //     title: "Editing",
    //     type: "item",
    //     url: "/job/editing",
    //     breadcrumbs: false,
    //     icon: icons.MdModeEditOutline
    //   },
  ],
};

export default CareerRoutes;