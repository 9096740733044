import { FaWarehouse } from "react-icons/fa";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { AiFillGold } from "react-icons/ai";

const icons = { FaWarehouse, MdOutlineDashboardCustomize, AiFillGold };

const ProductRoutes = {
  id: "product",
  title: "Product",
  type: "group",
  children: [
    {
      id: "inventory",
      title: "Inventory",
      type: "item",
      url: "/products/inventory",
      icon: icons.FaWarehouse,
      breadcrumbs: false,
    },
    {
      id: "bundles",
      title: "Bundles",
      type: "item",
      url: "/products/bundles",
      breadcrumbs: false,
      icon: icons.AiFillGold,
    },
    {
      id: "collections",
      title: "Collections",
      type: "item",
      url: "/products/collections",
      breadcrumbs: false,
      icon: icons.MdOutlineDashboardCustomize,
    },
  ],
};

export default ProductRoutes;
