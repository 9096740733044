import { alertConstants } from '../constants';

const success = (response) => ({
        type: alertConstants.SUCCESS,
        payload: response
})


const error = (err) => ({
        type: alertConstants.ERROR, 
        payload: err
})

const clear = () => ({
    type: alertConstants.CLEAR
})

export const alertActions = {
    success,
    error,
    clear
}
