import { apiRequest } from "../actions/api";
import { LOGIN, RESET_PASSWORD } from "../actions/auth";

export const authMiddleware = () => next => action => {
    next(action);
    switch (action.type){ 
        case LOGIN: {
            next(
                apiRequest({
                    url: `/admin/signin`,
                    method: "POST",
                    data: action.payload,
                })
            )
            break;
        }
        case RESET_PASSWORD: {
            next(
                apiRequest({
                    url: `/admin/reset-password`,
                    method: "PUT",
                    data: action.payload,
                })
            )
            break;
        }
        default: 
            break;
    }
}