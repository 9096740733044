import general from "./general";
import products from "./products";
import careers from "./careers";
// import user from "./user";
import utils from "./utilities";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [general, products,  careers, utils]
};

export default menuItems;
