import { lazy } from 'react';

// project imports
import Loadable from "components/Loadable";
import MinimalLayout from 'layout/MinimalLayout';
import { Navigate } from 'react-router-dom';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/authentication/authentication/Login')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = (isLoggedIn) => {
    return {
        path: '/',
        element: !isLoggedIn ? <MinimalLayout /> : <Navigate to="/overview/dashboard/" />,
        children: [
            {
                path: 'login',
                element: <AuthLogin />
            },
            {
                path: "/",
                element: <Navigate to="/login" />
            }
        ]
    }
};

export default AuthenticationRoutes;
