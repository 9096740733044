import { SET_PRODUCT_INVENTORY, SET_PRODUCT_BUNDLES, SET_PRODUCT_COLLECTIONS } from "store/constants"

export const setProductInventory = data => dispatch => {
    dispatch({
        type: SET_PRODUCT_INVENTORY,
        data: data
    })
}

export const setProductBundles = data => dispatch => {
    dispatch({ 
        type: SET_PRODUCT_BUNDLES,
        data: data
    })
}

export const setProductCollections = data => dispatch => {
    dispatch({ 
        type: SET_PRODUCT_COLLECTIONS,
        data: data
    })
}