// assets
import { RiSettings4Line } from 'react-icons/ri';

// constant
const icons = {
    RiSettings4Line
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Utilities',
    type: 'group',
    children: [
        {
            id: 'util-settings',
            title: 'Settings',
            type: 'item',
            url: '/utils/settings',
            icon: icons.RiSettings4Line,
            breadcrumbs: false
        }
    ]
};

export default utilities;
