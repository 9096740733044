import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "./ThemeContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useRoutes } from "react-router-dom";
import { ThemeProvider as Provider } from "@mui/material/styles";
import {
  CssBaseline,
  StyledEngineProvider,
  Alert as MuiAlert,
} from "@mui/material";
import { Auth, Hub } from "aws-amplify";
import config from "config";

// routing
import Routes from "../routes";

// defaultTheme
import themes from "themes";

// project imports
import NavigationScroll from "layout/NavigationScroll";
import toast, { Toaster } from "react-hot-toast";

// ==============================|| APP ||============================== //
// const stripePromise = loadStripe(process.env.STRIPE_KEY)

const App = () => {
  const customization = useSelector((state) => state.customization);
  const [isAuth, setIsAuth] = useState(null);

  const checkAuth = async () => {
    const user = await Auth.currentAuthenticatedUser();

    setIsAuth(user);
    
  };

  useEffect(() => {
    checkAuth();
  }, []);

  // const { isAuth } = useSelector((state) => state.user);
  const notify = (status, msg) => {
    if (status === "success") {
      toast.success(msg);
    } else {
      toast.error(msg);
    }
  };

  Hub.listen("alert", ({ payload }) => {
    const { data, event, message } = payload;

    notify(data.status, message);
  });

  Hub.listen("auth", ({ payload }) => {
    const { data, event, message } = payload;
    // console.log(payload)
    if (event === "signOut" && isAuth) {
      setIsAuth(null);
      notify("success", message);
    } else if (event === "signIn" && data.signInUserSession && !isAuth) {
      setIsAuth(true)
      notify("success", data.attributes.name);
    }
    
  });

  const routing = useRoutes(Routes(isAuth), config.basename);

  const options = {};

  return (
    <StyledEngineProvider injectFirst>
      {/* <Elements stripe={stripePromise} options={options}> */}
      <ThemeProvider>
        <Provider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>{routing}</NavigationScroll>
          <Toaster position="bottom-center" />
        </Provider>
      </ThemeProvider>
      {/* </Elements> */}
    </StyledEngineProvider>
  );
};

export default App;
