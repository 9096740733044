import { 
    SET_PRODUCT_BUNDLES,
    SET_PRODUCT_INVENTORY,
    SET_PRODUCT_COLLECTIONS
} from "store/constants/products";

const initialState = {
    inventory: [],
    bundles: [],
    collections: [],
}

const ProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PRODUCT_INVENTORY:
            return {
                ...state,
                inventory: action.data
            };
        case SET_PRODUCT_COLLECTIONS:
            return {
                ...state,
                collections: action.data
            }
        case SET_PRODUCT_BUNDLES:
            return {
                ...state,
                bundles: action.data
            }
        default: 
            return { ...state };
    }
}

export default ProductReducer;