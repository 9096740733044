export const reorder = ( list, startIndex, endIndex ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

export const formatNumber = (x) => {
    return x.toLocaleString()
}

export const getCurrTime = () => {
    const current = new Date().getHours();

    if (current >= 3 && current < 12) {
        return "Good Morning";
    } else if (current >= 12 && current < 17) {
        return "Good Afternoon";
    } else if (current >= 17 && current < 20) {
        return "Good Evening";
    } else if ((current >= 20 && current <= 23) || current < 3) {
        return "Have a Good Night";
    }
}

export const phoneRegex = /^\+[1-9]{1}[0-9]{1,14}$/