import { combineReducers } from 'redux';
import user from "./user";
import products from "./products";
import { alert } from './alert';
import styles from './styles';
// import LayoutReducer from './LayoutReducer';

const rootReducer = combineReducers({
    alert,
    user,
    products,
    customization: styles
})

export default rootReducer;